import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import ServiceSection from "~sections/LandingPages/Connections/Service"
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import ContentThree from '~sections/project/ContentThree'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Engagement/Hero/HeroSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Engagement() {
  return (
    <>
    <Helmet>
    <title>Revolutionize your LinkedIn messaging with our AI Writer.</title>
    <meta name="description" content="Revolutionize your LinkedIn messaging with our AI Writer. Easily personalize messages that are bound to receive a prospect's attention. Try it for free today!"/>
    <meta property="og:title" content="We-Connect | Revolutionize your LinkedIn messaging with our AI Writer."/>
    <meta property="og:description" content="Easily personalize messages that are bound to receive a prospect's attention using We-Connect AI Writer. Try it for free today!"/>
    <meta property="og:image" content="https://we-connect.io/we-connect_ai-writer-for-linkedin-page.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/ai-writer-for-linkedin"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="We-Connect | Revolutionize your LinkedIn messaging with our AI Writer. "/>
    <meta property="twitter:description" content="Easily personalize messages that are bound to receive a prospect's attention using We-Connect AI Writer. Try it for free today!  "/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect_ai-writer-for-linkedin-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <ServiceSection/>
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
